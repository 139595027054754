import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { ApolloClient, createHttpLink } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'
import firebase from 'firebase/compat/app'
import { isbot } from 'isbot'
import { GetServerSidePropsContext, PreviewData } from 'next'
import nookies, { parseCookies, setCookie } from 'nookies'
import config from './config'

if (!config.isProd) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { loadErrorMessages, loadDevMessages } = require('@apollo/client/dev')
  loadDevMessages()
  loadErrorMessages()
}

async function setUserTokenCookie(user: firebase.User): Promise<void> {
  const token = await user.getIdToken()
  setCookie(null, 'token', token, {
    maxAge: 30 * 24 * 60 * 60,
    path: '/'
  })
}

async function removeTokenCookie(): Promise<void> {
  document.cookie =
    'token' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
}

const getApolloClient = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context?: GetServerSidePropsContext<any, PreviewData>
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({ uri: config.graphqlUrl })

  const authLink = setContext(async (_, { headers }) => {
    const token = context
      ? nookies.get(context).token
      : await firebase.auth().currentUser?.getIdToken()
    const currency = nookies.get(context).currency
    const locale = nookies.get(context).locale

    if (!context && token) {
      setCookie(null, 'token', token, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/'
      })
    }
    const cookies = parseCookies(context)
    const utmParams = [
      'utm_page',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content'
    ].reduce((acc, param) => {
      if (cookies[param])
        return { ...acc, [param?.replace('_', '-')]: cookies[param] }
      return acc
    }, {})

    const userAgent = context
      ? context.req.headers['user-agent']
      : typeof navigator !== 'undefined'
        ? navigator.userAgent
        : ''
    const bot = isbot(userAgent)

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        currency,
        locale,
        'is-bot': bot.toString(),
        ...utmParams
      }
    }
  })

  const linkChain = createPersistedQueryLink({ sha256 })
    .concat(authLink)
    .concat(httpLink)

  const client = new ApolloClient({
    ssrMode: !!context,
    link: linkChain,
    cache: new InMemoryCache()
  })

  return client
}

export { removeTokenCookie, setUserTokenCookie }
export default getApolloClient
